<template>
  <b-card
    no-body
    class="user-target-ad-collapse"
    :class="{ open: visible }"
  >
    <b-card-header
      ref="myCollapsible"
      :class="{ collapsed: visible }"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
      @click="collapseOpen(!visible, $event)"
    >
      <slot name="header w-100 d-md-col">
        <div class="d-flex flex-row justify-content-between w-100">
          <div class="name-class">
            <b-link>
              <span
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.left="title"
                class="d-block title-class text-truncate"
              >
                {{ title }}
              </span>
            </b-link>
          </div>
          <div class="d-flex flex-row justify-content-between w-75">
            <div>
              <div
                class="time w-10"
              >
                <b-img
                  size="16px"
                  :src="require('@/assets/images/simiicons/wall-clock.svg')"
                />
                {{ time | moment }} to {{ time | targetAdsUptoTimeMoment }}
              </div>
            </div>
            <div class="d-flex flex-row align-items-center mt-1 mt-md-0">
              <div
                v-if="approved !== null"
                id="btn-class"
                @click="disableCollapse($event)"
              >
                <span
                  v-if="canApproveTargetAdTimeCondition"
                  class="font-small-3 font-weight-bold completed-tag-class"
                >Completed</span>
                <span
                  v-else
                  class="font-small-3 font-weight-bold  approval-tag-class"
                >Approved</span>
              </div>
              <div v-else>
                <div v-if="canApproveTargetAdTimeCondition ">
                  <div
                    v-if=" targetAd.cancelled_by === null"
                    class="font-small-3 font-weight-bold  expired-tag-class"
                  >Expired</div>
                  <div v-else>
                    <span
                      v-b-popover.hover.top="`${targetAd.reject_reason}`"
                      title="Rejection reason"
                      class="font-small-3 font-weight-bold rejected-tag-class"
                    >Rejected</span>
                  </div>
                </div>
                <div v-else>
                  <div v-if="targetAd.cancelled_by === null">
                    <span v-if="approvalSpinner[id]">
                      <div
                        style="width: 75px;"
                        class="spinner text-center"
                      >
                        <b-spinner
                          variant="primary"
                          small
                        />
                      </div>
                    </span>
                    <span v-else>
                      <span>
                        <b-button
                          class="color-btn-class"
                          @click="approveTargetAdsFunc($event)"
                        >
                          <span class="font-small-3 font-weight-bold ">Approve</span>
                        </b-button>
                      </span>
                      <span v-if="rejectionSpinner[id]">
                        <div
                          style="width: 75px;"
                          class="spinner text-center"
                        >
                          <b-spinner
                            variant="primary"
                            small
                          />
                        </div>
                      </span>
                      <span v-else>
                        <span class="ml-1">
                          <b-button
                            variant="danger"
                            @click="rejectTargetAdsFunc($event)"
                          >
                            <span class="font-small-3 font-weight-bold ">Reject</span>
                          </b-button>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div v-else>
                    <span
                      v-b-popover.hover.top="`${targetAd.reject_reason}`"
                      title="Rejection reason"
                      class="font-small-3 font-weight-bold rejected-tag-class "
                    >Rejected</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-10 review d-flex justify-content-start align-items-end justify-content-md-end align-content-md-start">
            <feather-icon
              v-if="approved && !canApproveTargetAdTimeCondition"
              :id="id"
              icon="Edit2Icon"
              class="cursor-pointer"
              size="16"
              style="margin: 8px"
              @click="updateTargetAdTime($event)"
            />
            <feather-icon
              :id="id"
              icon="EyeIcon"
              class="cursor-pointer"
              size="16"
              style="margin: 8px"
              @click="review($event)"
            />
          </div>
        </div>
      </slot>
    </b-card-header>
    <div v-if="visible">
      <hr>
      <b-row>
        <b-col
          lg="2"
          md="12"
          sm="12"
        >
          <div class="device">
            Parameters
          </div>
        </b-col>
        <b-col
          class="location-class ml-1 ml-lg-0"
          lg="10"
          md="12"
          sm="12"
        >
          <div>Location</div>
          <div class="font-weight-normal">
            {{ targetAd.location_address }}
          </div>
        </b-col>
      </b-row>
    </div>
    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body style="padding: 0px !important">
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BLink,
  BImg,
  BSpinner,
  VBPopover,
  VBToggle,
  VBTooltip,
  BButton,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import { v4 as uuidv4 } from 'uuid'
import Ripple from 'vue-ripple-directive'
import 'vuesax/dist/vuesax.css'
import Vuesax from 'vuesax'
import Vue from 'vue'
import 'swiper/css/swiper.css'
import '@/assets/images/simiicons/edit.svg'
import moment from 'moment'
import { showToast } from '@/common/global/functions'

Vue.prototype.moment = moment
Vue.use(Vuesax)
Vue.directive('ripple', Ripple)

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BLink,
    BImg,
    BSpinner,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    'b-popover': VBPopover,
  },
  filters: {
    moment(date) {
      return moment.utc(date).format('DD-MM-YYYY, HH:mm')
    },
    targetAdsUptoTimeMoment(date) {
      return moment.utc(date).add(5, 'minutes').format('HH:mm')
    },
  },
  // eslint-disable-next-line vue/require-prop-types
  props: {
    title: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    targetAd: {
      type: Object,
      required: true,
    },
    approved: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      rejectTargetAds: {
        targetAdId: null,
        reject_reason: null,
      },
      allowTime: false,
      approvalSpinner: {},
      rejectionSpinner: {},
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
      // validation
      required,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion
        ? `accordion-${this.$parent.collapseID}`
        : null
    },
    canApproveTargetAdTimeCondition: {
      get() {
        return this.allowTime
      },
      set() {
        //
      },
    },
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  mounted() {
    this.disabledTimeFunc()
  },
  methods: {
    updateTargetAdTime(e) {
      e.preventDefault()
      e.stopPropagation()
      if (!this.targetAd.target_ads_payment_hold) {
        showToast('Target Ad', 'Target Ad booked for more than 9 days cannot be edited!', 'danger', 4000)
      } else {
        this.$emit('updateTargetAdTime', { id: this.id, expires_at: this.targetAd?.target_ads_payment_hold?.expires_at })
      }
    },
    disabledTimeFunc() {
      this.currentTime = moment.utc().format('YYYY-MM-DD[T]HH:mm ')
      const targetAdsTime = moment.utc(this.time).format('YYYY-MM-DD[T]HH:mm ')
      this.allowTime = targetAdsTime < this.currentTime
    },
    async rejectTargetAdsFunc(e) {
      this.disabledTimeFunc()
      e.preventDefault()
      e.stopPropagation()
      if (this.canApproveTargetAdTimeCondition) {
        showToast('Reject Target Ad', 'Time is expired!')
        await this.$emit('updateSwitchState')
      } else {
        this.openConfirmationModal()
      }
    },
    openConfirmationModal() {
      this.$swal({
        title: '<span class="font-weight-bolder">Reject Target Ads ?</span>',
        icon: 'question',
        html:
                    'Reason to reject target ads ',
        input: 'textarea',
        inputPlaceholder: 'Enter your reason to reject',
        showCloseButton: false,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Save',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn btn-primary ',
          cancelButton: 'btn btn-outline-danger ml-1',
          icon: 'primary',
        },
        buttonsStyling: false,
        inputValidator: value => new Promise(resolve => {
          if (value !== '') {
            resolve()
          } else {
            resolve('You need to write rejection reason!')
          }
        }),
        allowOutsideClick: () => !this.$swal.isLoading(),
      })
        .then(result => {
          if (result.value) {
            this.$swal({
              icon: 'success',
              title: 'Rejected!',
              text: 'Target Ad has been rejected.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.rejectTargetAds.reject_reason = result.value
            this.rejectTargetAdsById()
          }
        })
    },
    async rejectTargetAdsById() {
      Vue.set(this.rejectionSpinner, `${this.id}`, true)
      try {
        this.rejectTargetAds.targetAdId = this.id
        await this.$axios
          .put('admin/target-ad/reject-target-ad/', this.rejectTargetAds)
        await this.$emit('updateSwitchState')
        Vue.set(this.approvalSpinner, `${this.id}`, false)
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        this.$swal(message)
        Vue.set(this.rejectionSpinner, `${this.id}`, false)
      }
    },
    async approveTargetAdsFunc(e) {
      this.disabledTimeFunc()
      e.preventDefault()
      e.stopPropagation()
      if (this.canApproveTargetAdTimeCondition) {
        showToast('Approve Target Ad', 'Time is expired!')
        await this.$emit('updateSwitchState')
      } else {
        Vue.set(this.approvalSpinner, `${this.id}`, true)
        try {
          await this.$axios
            .put(`admin/target-ad/approve-target-ad/${this.id}`)
          await this.$emit('updateSwitchState')
          Vue.set(this.approvalSpinner, `${this.id}`, false)
        } catch ({
          response: {
            data: {
              statusCode, message,
            },
          },
        }) {
          this.$swal(message)
          Vue.set(this.approvalSpinner, `${this.id}`, false)
        }
      }
    },
    collapseOpen(val, e) {
      e.preventDefault()
      e.stopPropagation()
      this.visible = val
    },
    collapseClose() {
      if (this.openOnHover) {
        this.visible = false
      }
    },
    disableCollapse(e) {
      e.preventDefault()
      e.stopPropagation()
    },
    async review(e) {
      e.preventDefault()
      e.stopPropagation()
      const allMedia = this.targetAd.target_ads_has_media.map(media => media.media)
      if (allMedia) {
        await this.$store.dispatch('lightBox/lightBoxData', allMedia)
      }
    },
  },
}
</script>
<style lang="scss">
.user-target-ad-collapse {
  .switches {
    border-radius: 25px;
  }
  .device {
    font-size: 14px;
    letter-spacing: 0;
    color: #414141;
    font-weight: 600;
    font-family: "Montserrat";
    padding-left: 15px;
  }
  .title-class {
    font-size: 16px;
    letter-spacing: 0px;
    font-weight: 600;
    font-family: "Montserrat";
  }
  .time {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    color: #414141;
    font-weight: 500;
    font-family: "Montserrat";
    min-width: 240px;
  }
  .name-class {
    width: 300px !important;
    @media (max-width: 1000px) {
      //min-width: 200px;
      width: 200px !important;
    }
  }
  #btn-class {
    .btn-secondary {
      background-color: #00E265 !important;
      border-color: #00E265 !important;
      color: black !important;
    }
  }
  .disableIcon {
    color: #80808080;
  }
  .review {
    min-width: 10px;
  }
  .location-class {
    font-size: 14px;
    letter-spacing: 0;
    color: #414141;
    font-weight: 500;
    font-family: "Montserrat";
  }
  .color-btn-class{
    background-color: rgba(0, 175, 18, 1) !important;
    color:  white !important;
  }
}
</style>

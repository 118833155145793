<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    class="position-relative"
  >
    <b-overlay
      variant="white"
      spinner-variant="primary"
      opacity="0.5"
    >
      <div
        class="
          modal1
          d-flex
          justify-content-center
          align-content-center align-items-center
          m-1
          mb-2
        "
      >
        <b-row class="d-block w-75">
          <div class="close-div mr-1 mt-1">
            <b-button
              variant="transparent"
              @click="hide()"
            >
              <feather-icon
                icon="XIcon"
                class="cursor-pointer"
                size="16"
              />
            </b-button>
          </div>
          <div
            class="
              d-inline-block d-flex
              justify-content-center
              align-content-center align-items-center
              mb-1
            "
          >
            <span class="font-weight-bolder heading1 mt-0">
              Update Target Ad Time
            </span>
          </div>
          <validation-observer ref="updateTargetAdTime">
            <b-row
              class="mb-2 d-flex"
            >
              <b-col
                id="calender"
                cols="7"
              >
                <b-form-group
                  text-bolder
                  label="Date"
                  label-for="Date"
                  class="campaign-label"
                >
                  <validation-provider
                    name="Date"
                  >
                    <b-form-datepicker
                      v-model="dateTimeSelectedFromSelectors.date"
                      placeholder="Choose a date"
                      locale="en"
                      :max="maximumDateTimeCanBeSelected.date"
                      :date-disabled-fn="disabledDates"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                id="time"
                cols="5"
              >
                <b-form-group
                  text-bolder
                  label="Time"
                  label-for="Time"
                  class="campaign-label mb-0"
                >
                  <vue-timepicker
                    v-model="dateTimeSelectedFromSelectors.time"
                    :disabled="!dateTimeSelectedFromSelectors.date"
                    input-class="custom-picker"
                    format="HH:mm UTC"
                    :close-on-complete="true"
                    :minute-interval="5"
                    :hour-range="enabledHours"
                    :minute-range="enabledMinutes"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
          <div class="d-flex justify-content-center">
            <div class="w-100">
              <slot
                name="body"
              />
            </div>
          </div>
          <div class="d-flex justify-content-around">
            <div class="w-100 mr-1">
              <b-button
                block
                variant="secondary"
                @click="hide"
              >
                <feather-icon
                  icon="XCircleIcon"
                  size="15"
                />
                Cancel
              </b-button>
            </div>
            <div class="w-100">
              <b-button
                block
                variant="primary"
                :disabled="spinner"
                @click="updateMyTargetAdTime"
              > <div
                  v-if="spinner"
                  class="spinner"
                >
                  <b-spinner
                    small
                  />
                </div>
                <feather-icon
                  icon="CheckSquareIcon"
                  size="15"
                />
                Update
              </b-button>
            </div>
          </div>
        </b-row>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BRow,
  BOverlay,
  BCol, BSpinner,
  BFormGroup, BFormDatepicker,
} from 'bootstrap-vue'
import moment from 'moment/moment'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import {
  getLowestUpdateTimeForTargetAd,
  showErrorMessage,
  showToast,
} from '@/common/global/functions'

export default {
  name: 'UpdateTargetAdTime',
  components: {
    BRow,
    BButton,
    BModal,
    BOverlay,
    BCol,
    BFormGroup,
    BSpinner,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    VueTimepicker,
  },
  data() {
    return {
      showModal: false,
      spinner: false,
      postData: {
        targetAdId: null,
        time: null,
      },
      lowestDateTimeCanBeSelected: {
        dateTime: null,
        date: null,
        time: null,
      },
      maximumDateTimeCanBeSelected: {
        dateTime: null,
        date: null,
        time: null,
      },
      dateTimeSelectedFromSelectors: {
        date: null,
        time: null,
      },
    }
  },
  computed: {
    enabledHours() {
      if (this.dateTimeSelectedFromSelectors.date === this.lowestDateTimeCanBeSelected.date) {
        return [[moment(this.lowestDateTimeCanBeSelected.time, 'HH:mm').get('hour'), 23]]
      }
      if (this.dateTimeSelectedFromSelectors.date === this.maximumDateTimeCanBeSelected.date) {
        return [[0, moment(this.maximumDateTimeCanBeSelected.time, 'HH:mm').subtract(5, 'minute').get('hour')]]
      }
      return [[0, 23]]
    },
    enabledMinutes() {
      if (!this.dateTimeSelectedFromSelectors.time || !this.dateTimeSelectedFromSelectors.time.HH) {
        return [[]]
      }
      let lowestDateTimeCanBeSelectedInRequiredFormat = null
      lowestDateTimeCanBeSelectedInRequiredFormat = moment(this.lowestDateTimeCanBeSelected.time, 'HH:mm').hours()
      if (this.dateTimeSelectedFromSelectors.time && this.dateTimeSelectedFromSelectors.time.HH && this.dateTimeSelectedFromSelectors.date === this.lowestDateTimeCanBeSelected.date && ((`0${lowestDateTimeCanBeSelectedInRequiredFormat}`).slice(-2).toString()) === this.dateTimeSelectedFromSelectors.time.HH.toString()) {
        return [[moment(this.lowestDateTimeCanBeSelected.time, 'HH:mm').get('minutes'), 59]]
      }
      let maximumDateTimeCanBeSelectedInRequiredFormat = null
      maximumDateTimeCanBeSelectedInRequiredFormat = moment(this.maximumDateTimeCanBeSelected.time, 'HH:mm').hours()
      if (this.dateTimeSelectedFromSelectors.time && this.dateTimeSelectedFromSelectors.time.HH && this.dateTimeSelectedFromSelectors.date === this.maximumDateTimeCanBeSelected.date && ((`0${maximumDateTimeCanBeSelectedInRequiredFormat}`).slice(-2).toString()) === this.dateTimeSelectedFromSelectors.time.HH.toString()) {
        return [[0, moment(this.maximumDateTimeCanBeSelected.time, 'HH:mm').get('minutes')]]
      }
      return [[0, 59]]
    },
    isValidDateTimeSelected() {
      if (this.dateTimeSelectedFromSelectors.time && this.dateTimeSelectedFromSelectors.time.HH && this.dateTimeSelectedFromSelectors.time.mm) {
        const selectedDateTime = moment.utc(`${this.dateTimeSelectedFromSelectors.date} ${this.dateTimeSelectedFromSelectors.time.HH}:${this.dateTimeSelectedFromSelectors.time.mm}`, 'YYYY-MM-DD HH:mm')
        return (selectedDateTime.isAfter(this.lowestDateTimeCanBeSelected.dateTime) || selectedDateTime.isSame(this.lowestDateTimeCanBeSelected.dateTime.format()))
      }
      return false
    },
  },
  async mounted() {
    this.updateTimeCanBeSelected()
  },
  methods: {
    show(id, expiryTime) {
      if (id && expiryTime) {
        this.postData.targetAdId = id
        const maximumDateTime = moment(expiryTime)
        this.maximumDateTimeCanBeSelected.dateTime = maximumDateTime
        this.maximumDateTimeCanBeSelected.date = maximumDateTime.format('YYYY-MM-DD')
        this.maximumDateTimeCanBeSelected.time = maximumDateTime.format('HH:mm')
        this.dateTimeSelectedFromSelectors.time = null
        this.dateTimeSelectedFromSelectors.date = null
        this.showModal = true
      }
    },
    hide() {
      this.showModal = false
    },
    disabledDates(ymd, date) { // Will check if the date can be selected or not
      return (moment(date).isBefore(moment(this.lowestDateTimeCanBeSelected.date, 'YYYY-MM-DD')))
    },
    updateTimeCanBeSelected() { // will update datetime object according to prior time condition
      const lowestDateTime = getLowestUpdateTimeForTargetAd()
      this.lowestDateTimeCanBeSelected.dateTime = lowestDateTime
      this.lowestDateTimeCanBeSelected.date = lowestDateTime.format('YYYY-MM-DD')
      this.lowestDateTimeCanBeSelected.time = lowestDateTime.format('HH:mm')
    },
    formatDateTimeInFormData() {
      if (this.dateTimeSelectedFromSelectors.date && this.dateTimeSelectedFromSelectors.time && this.dateTimeSelectedFromSelectors.time.HH && this.dateTimeSelectedFromSelectors.time.mm) {
        this.postData.time = moment.utc(`${this.dateTimeSelectedFromSelectors.date} ${this.dateTimeSelectedFromSelectors.time.HH}:${this.dateTimeSelectedFromSelectors.time.mm}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD[T]HH:mm')
      }
    },
    async updateMyTargetAdTime() {
      this.$refs.updateTargetAdTime.validate().then(async success => {
        if (success) {
          this.formatDateTimeInFormData()
          this.spinner = true
          if (this.postData.targetAdId) {
            if (this.postData.time === null) {
              showToast('Update Target Ad Time', 'Cannot update please select correct date and time', 'danger', 4000)
            } else {
              await this.targetAdTimeUpdated()
            }
          }
          this.spinner = false
        }
      })
    },
    async targetAdTimeUpdated() {
      try {
        await this.$axios
          .put('admin/target-ad/update-target-ad-time', this.postData)
        showToast('Update Target Ad Time', 'Target Ad Time has been updated successfully!')
        this.$emit('refreshAllTargetAds')
        this.hide()
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        if (showErrorMessage(statusCode)) {
          showToast('Update Target Ad Time', message.toString(), 'danger')
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.modal1 {
  .time-picker{
    width: 100%;
    .custom-picker {
      padding: 0.438rem 1rem;
      border: 1px solid #d8d6de;
      border-radius: 0.357rem;
      height: 40px !important;
      width: 100%;
      font-size: 1.1rem;
      color: #6e6b7b;
      font-weight: 400;
      font-family: "Montserrat";
    }
  }
  .campaign-label{
    font-size: 13px;
    line-height: 18px;
    color: #1f58b5;
    font-weight: 600;
    font-family: "Montserrat";
  }
  #calender {
    .dropdown-menu{
      z-index: 12 !important;
    }
  }
  #time{
    .vue__time-picker .controls .char{
      display: none !important;
    }
  }

  .close-div {
    justify-content: end;
    display: flex;
    float: right;
    margin-right: 0 !important;
    position: absolute;
    padding-right: 0 !important;
    top: -15px;
    right: 0;
  }
}
</style>
